import React from "react";
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'

export const Input = ({ register, label, type, name, errors, required, ...props }) => {
  // a generic input component that can be used for any field
  
  return (
    <>
    {/* <div className="col-md-6"> */}
      {/* <label className="form-title">{label}</label> */}
      <input
        type={type}
        placeholder={label + (required ? " *":"")}
        className={`form-control input-bordered input w-full ${
          errors[name] && "input-error"
        } bg-gray-50 px-2 py-2`}
        {...register(name)}
        {...props}
      />
      <span className="text-error">{errors[name] && errors[name].type!=="required" ? errors[name].message : ""}</span>
    {/* </div> */}
    </>
  );
};

export const BooleanInput = ({ register, label, name, errors, required, ...props }) => {
  // a checkbox component that can be used for boolean fields
  return (
    <>
    {/* <div className="col-md-6 inline-flex"> */}
      <div className="w-full form-control-boolean">
        <input
          type="checkbox"
          // placeholder={label}
          className={`input-bordered input toggle-primary toggle toggle-lg ${
            errors[name] && "input-error"
          } bg-gray-50 px-2 py-2`}
          {...register(name)}
          {...props}
        />
        <label className="form-title">{label + (required ? " *":"")}</label>
        <span className="text-error">{errors[name]?.type!=="required"?.message}</span>
      </div>
    {/* </div> */}
    </>
  );
};

export const SearchableSelect = ({ label, onChange, required, multiple, ...props }) => {
  // a searchable select component that can be used for any choice fields
  return (
    <>
      {props.help_text!=="choicesCreatable" ?
      <Select  {...props} classNamePrefix="form-select" placeholder={label + (required ? " *":"")} onChange={onChange}/>
      : <CreatableSelect  {...props} classNamePrefix="form-select" placeholder={label + (required ? " *":"")}  onChange={onChange}/>
      }
    </>
  );
};

export function TextAreaInput({ register, label, type, name, errors, required, ...props }) {
  // a textarea component that can be used for any text fields
  return (
    <>
    {/* <div className="textarea col-xs-12"> */}
      {/* <label className="form-title">{label}</label> */}
      <div className="w-full">
        <textarea
          rows="5"
          placeholder={label + (required ? " *":"")}
          className={`form-control textarea-bordered textarea w-full ${
            errors[name] && "input-error"
          } bg-gray-50 px-2 py-2`}
          {...register(name)}
          {...props}
        />
        <span className="text-error">{errors[name]?.type!=="required"?.message}</span>
      </div>
    {/* </div> */}
    </>
  );
}
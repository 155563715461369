import { Outlet } from "react-router-dom";

import Head from './Head';
import Foot from './Foot';

const Base = (props) => {
    return (
      <>
        <Head menu={props.menuItems}/>
        <Outlet />
        <Foot menu={props.menuItems}/>
      </>
    )
  };
  
  export default Base;
import { generateYupSchema } from "./yupSchemaGenerator";

export const removeKeys = (schema, excludeFields = []) => {
  return Object.keys(schema)
    .filter((key) => !schema[key].read_only) // remove read_only fields
    .reduce((acc, key) => {
      // remove excluded fields
      if (!excludeFields.includes(key)) {
        // delete the read_only attribute of object, this causes errors in react
        delete schema[key].read_only;
        acc[key] = schema[key];
      }
      return acc;
    }, {});
};

export const transformInputTypes = (schema) => {
  Object.keys(schema).forEach((key) => {
    if (
      schema[key].type === "integer" ||
      schema[key].type === "decimal" ||
      schema[key].type === "float" ||
      schema[key].type === "number"
    ) {
      schema[key].type = "number";
    }
    if (
      schema[key].type === "file upload" ||
      schema[key].type === "image upload"
    ) {
      schema[key].type = "file";
    }
    if (schema[key].type === "boolean") {
      schema[key].type = "checkbox";
    }

    if (schema[key].type === "nested object") {
      schema[key].type = "text";
      // deleting the nested object so that it doesn't get rendered in the form
      delete schema[key].children;
    }

    if (schema[key].type === "string") {
      schema[key].type = "text";
    }

    if (schema[key].type === "datetime") {
      schema[key].type = "datetime-local";
    }

    if (schema[key].type === "choice") {
      schema[key].type = "select";
      schema[key].options = schema[key].choices.map((choice) => ({
        value: choice.value,
        label: choice.display_name,
      }));
    }

    if (key === "description" || key === "comment") {
      schema[key].type = "textarea";
    }
  });
  return schema;
};

export const buildFormShape = (schema) => {
  return Object.keys(schema).map((key) => {
    return {
      name: key,
      ...schema[key],
    };
  });
};

export const buildForm = ({ JSONschema, exclude = [] }) => {
  let filtered = removeKeys(JSONschema, exclude);
  let updatedSchema = transformInputTypes(filtered);

  let schema = generateYupSchema(updatedSchema);
  let formFields = buildFormShape(updatedSchema);

  return { formFields, schema };
};
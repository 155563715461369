import './static/css/animate.css';
import './static/css/icomoon.css';

// Bootstrap CSS
//import "bootstrap/dist/css/bootstrap.min.css";
import "./static/css/bootstrap.css"
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";

import './static/css/flexslider.css';
import './static/css/style.scss';

import React from 'react';
import ReactDOM from 'react-dom/client';
import Base from './Base';
import Home from './Home';
import NoPage from './NoPage';
import reportWebVitals from './reportWebVitals';

import { BrowserRouter, Routes, Route } from "react-router-dom";

import Etudiants, {HackteurInscription, MissionInscription} from './Etudiants';
import Contact from './Contact'
import Structure, {DemandeStructure} from './Structure';

const root = ReactDOM.createRoot(document.getElementById('root'));

const menuItems= [
  {
      title: "Accueil",
      url: "/"
  },
  {
      title: "Étudiant",
      url: "/etudiant",
      submenu: [
        {
          title: "S'engager !",
          url: '/etudiant/inscription',
        },
        {
          title: "Candidater à une mission",
          url: '/etudiant/candidature-mission',
        },
      ]
  },
  {
      title: "Structure",
      url: "/structure",
      submenu : [
        {
          title: "Demander un coup de pouce",
          url: '/structure/demande-mission',
        },
      ]
  },
  {
      title: <span>Contact</span>,
      url: "/contact",
      class: "btn-cta",
  },
  // {
  //     title: <span>Login</span>,
  //     url: "/login",
  //     class: "btn-cta",
  // },
]

root.render(
  <BrowserRouter>
      <Routes>
        <Route path="/" element={<Base menuItems = {menuItems}/>}>
          <Route index element={<Home />} />
            
          <Route path="/etudiant/inscription" element={<HackteurInscription />} />
            <Route path="/etudiant/candidature-mission" element={<MissionInscription />} />
            <Route path="/etudiant" element={<Etudiants />} />

            <Route path="/contact" element={<Contact />} />

            <Route path="/structure/demande-mission" element={<DemandeStructure />} />
          <Route path="/structure" element={<Structure />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

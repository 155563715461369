import React, { useState } from "react"
import { Waypoint } from "react-waypoint"


export default function Content(props) {
    const [animate, setAnimate] = useState(false)


    console.log(props)
    return (
        <Waypoint
            onEnter={() => setAnimate(true)}
            scrollableAncestor={"window"}>
        <div id="fh5co-content-section">
        <div className="container">
            <div className="row row-bottom-padded-md">
                <div className={`col-md-8 col-md-offset-2 text-center heading-section animate-box ${animate ? "fadeInUp animated-fast" : ""}`}>
                    <h3>{props.title}</h3>
                    <p>{props.text}</p>
                </div>
            </div>
        </div>
        <div className="container">
            <div className="row">
                {props.items.map((item, index) => {
                    return <Element key={index} animate={animate} {...item} />
                    })}
            </div>
        </div>
        </div>
        </Waypoint>
    )
}

function Element(props) {
    return (
    <div className="col-md-4">
        <div className={`fh5co-team text-center animate-box ${props.animate ? "fadeInUp animated-fast" : ""}`}>
            {props.img ? <figure>
                <img src={props.img}/>
            </figure> : <></>}
            <p>{props.title}</p>
            <blockquote>
                <p>{props.text}</p>
            </blockquote>
        </div>
    </div>
    )
}
import React, { useState } from "react"
import { Waypoint } from "react-waypoint";
import Form from "./forms/loader-form";


export default function Contact(props) {
    const [animate, setAnimate] = useState(true)

    return(
        <div id="fh5co-contact">
            <Waypoint
            onEnter={() => setAnimate(true)}
            scrollableAncestor={window}>
            <div className="container">
                <div className={`row animate-box ${animate ? "fadeInUp animated-fast" : ""}`}>
                    <div className="col-md-8 col-md-offset-2 text-center fh5co-heading">
                        <h2>Nous contacter</h2>
                        <p>Que vous ayez une question, un besoin, ou êtes intéressés par notre association, n'hésitez pas.</p>
                        <p>Un membre vous répondra rapidement.</p>
                    </div>
                </div>
            </div>
            </Waypoint>
            

            <div className="container">
                <div className="row">
                    <div className={`col-md-3 col-md-push-1 animate-box ${animate ? "fadeInUp animated-fast" : ""}`}>
                        {/* <h3>Notre adresse</h3> */}
                        <ul className="contact-info">
                            <li><i className="icon-location"></i>IMT Atlantique</li>
                            <li><i className="icon-mail22"></i><a href="mailto:atlantique.for.good@gmail.com">atlantique.for.good@gmail.com</a></li>
                            {/* <li><i className="icon-old-phone"></i>+33 6 07 08 09 10</li> */}
                        </ul>
                    </div>
                    
                    <div className={`col-md-7 col-md-push-1 animate-box ${animate ? "fadeInUp animated-fast" : ""}`}>
                    <Form path="contact"/>
                    </div>
                </div>
            </div>
        <div style={{background:"#DDD", height:"1px", width:"70%", margin:"0 15%"}}></div>
        </div>
    )
}
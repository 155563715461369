import React from "react";

function Icon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={props.size ? props.size : "48"}
      height={props.size ? props.size : "48"}
      version="1.1"
      viewBox="0 0 12.7 12.7"
    >
      <defs>
        <linearGradient id="linearGradient1254">
          <stop offset="0" stopColor="#0c2340" stopOpacity="1"></stop>
          <stop offset="1" stopColor="#00b8de" stopOpacity="1"></stop>
        </linearGradient>
        <marker orient="auto" overflow="visible" refX="0" refY="0">
          <path
            fillRule="evenodd"
            stroke="#000"
            strokeWidth=".8pt"
            d="M-4-4v8h8v-8z"
          ></path>
        </marker>
        <linearGradient
          id="linearGradient1256"
          x1="99.711"
          x2="94.48"
          y1="72.591"
          y2="48.312"
          gradientTransform="translate(13.507 37.957) scale(.10678)"
          gradientUnits="userSpaceOnUse"
          xlinkHref="#linearGradient1254"
        ></linearGradient>
      </defs>
      <g display="inline" transform="translate(-17.239 -36.445)">
        {props.tite ?
        <text
          xmlSpace="preserve"
          style={{
            lineHeight: "1.15",
            InkscapeFontSpecification: "'sans-serif Bold'",
            WebkitTextAlign: "center",
            textAlign: "center",
          }}
          x="23.316"
          y="48.147"
          fill="#0c2340"
          fillOpacity="1"
          strokeWidth="0.034"
          display="inline"
          fontFamily="sans-serif"
          fontSize="0.801"
          fontWeight="bold"
          letterSpacing="0.138"
          textAnchor="middle"
          transform="scale(1.0121 .98805)"
          wordSpacing="0.173"
        >
          <tspan
            x="23.385"
            y="48.147"
            style={{}}
            fill="#0c2340"
            fillOpacity="1"
            strokeWidth="0.034"
            direction="ltr"
            fontFamily="Arial Rounded MT Bold"
            fontSize="0.801"
            fontStretch="normal"
            fontStyle="normal"
            fontVariant="normal"
            fontWeight="bold"
          >
            ATLANTIQUE FOR GOOD
          </tspan>
        </text>
        : ""}
        <g
          aria-label="A"
          style={{
            lineHeight: "1.25",
            InkscapeFontSpecification: "'sans-serif Bold'",
            WebkitTextAlign: "center",
            textAlign: "center",
          }}
          strokeWidth="1.223"
          display="inline"
          fontFamily="sans-serif"
          fontSize="48.903"
          fontWeight="bold"
          letterSpacing="0"
          textAnchor="middle"
          transform="translate(11.666 34.065) scale(.10678)"
        >
          <path
            d="M107.175 81.817l-5.277-14.446-5.181 14.446-1.863 5.325-1.671 4.489c-.653 1.751-1.21 2.937-1.672 3.558-.461.605-1.218.907-2.268.907-.892 0-1.68-.326-2.364-.979-.685-.652-1.027-1.393-1.027-2.22 0-.478.08-.971.239-1.48.159-.51.422-1.219.788-2.126l8.954-22.732c.255-.653.557-1.433.908-2.34.366-.923.748-1.688 1.146-2.292a4.689 4.689 0 011.6-1.457c.668-.382 1.488-.573 2.459-.573.987 0 1.807.191 2.46.573a4.5 4.5 0 011.6 1.433 9.178 9.178 0 011.026 1.91 73.47 73.47 0 011.075 2.698l9.145 22.589c.716 1.72 1.074 2.969 1.074 3.749 0 .812-.342 1.56-1.026 2.244-.669.669-1.48 1.003-2.436 1.003-.557 0-1.035-.103-1.433-.31a2.87 2.87 0 01-1.003-.788c-.27-.35-.565-.876-.883-1.576a232.75 232.75 0 01-.788-1.886l-1.671-4.394z"
            style={{}}
            fill="#0c2340"
            fillOpacity="1"
            strokeWidth="1.223"
            fontFamily="Arial Rounded MT Bold"
            fontStretch="normal"
            fontStyle="normal"
            fontVariant="normal"
            fontWeight="bold"
          ></path>
        </g>
        <path
          fill="url(#linearGradient1256)"
          fillOpacity="1"
          strokeWidth="0.028"
          d="M29.043 46.395c-.909-.399-1.436-.545-2.176-.603-.294-.023-.648.045-1.033.197-.736.29-1.459.393-1.97.28a3.092 3.092 0 01-.766-.302c-.254-.15-.538-.283-.68-.321a2.358 2.358 0 00-.452-.038c-.386 0-.674.064-1.086.244-.68.297-1.125.405-1.665.405-.308 0-.404-.011-.615-.072-.34-.099-.5-.19-.722-.415-.426-.429-.617-.983-.618-1.794-.002-.802.162-1.522.551-2.42.326-.752.908-1.49 1.501-1.9.317-.22.894-.462 1.1-.462.238 0 .41.174.432.438.018.218-.081.385-.27.454-.279.101-.376.153-.635.335-.374.263-.818.788-1.092 1.29-.123.227-.277.76-.346 1.198-.045.294-.045 1.217.001 1.453.067.346.252.615.477.695.08.028.262.053.458.062.411.018.843-.054 1.384-.232.504-.165.807-.241 1.064-.266.405-.038.881.051 1.357.254.625.268.93.342 1.408.343.37 0 .541-.03 1.194-.217 1.015-.29 1.1-.304 1.596-.266.57.044 1.97.485 2.372.747l.106.069-.056.12c-.03.066-.123.285-.205.487-.132.325-.157.368-.22.374-.038.003-.215-.058-.394-.137z"
        ></path>
        <path
          fill="#00b8de"
          fillOpacity="1"
          strokeWidth="0.028"
          d="M24.186 41.079c-.05-.201.095-.563.274-.682.1-.065.118-.069.196-.037.104.043.15.121.125.206-.033.105-.259.405-.376.498-.135.106-.195.11-.219.015z"
          display="inline"
        ></path>
        <path
          fill="#00b8de"
          fillOpacity="1"
          strokeWidth="0.028"
          d="M20.748 41.124c-.053-.047-.104-.079-.114-.071-.01.007-.012.005-.005-.005.008-.01-.016-.058-.052-.106-.117-.154-.16-.265-.16-.415v-.146l.14.014a.579.579 0 01.215.062c.093.061.199.293.223.488.015.126.009.157-.04.205-.075.076-.094.073-.207-.026z"
          display="inline"
        ></path>
        <path
          fill="#00b8de"
          fillOpacity="1"
          strokeWidth="0.028"
          d="M24.824 41.73c-.104-.126-.025-.558.114-.627a.397.397 0 01.36.02c.081.049.092.07.092.179 0 .164-.101.351-.226.418-.12.064-.29.069-.34.01z"
          display="inline"
        ></path>
        <path
          fill="#9c3"
          fillOpacity="1"
          strokeWidth="0.028"
          d="M19.895 41.753c-.142-.035-.282-.249-.312-.478-.016-.117-.01-.146.039-.19.135-.123.404-.098.531.049.034.039.07.062.08.051.01-.01.009.006-.002.035-.012.03-.01.047.005.037.03-.018.052.266.027.345-.032.1-.235.184-.368.15z"
          display="inline"
        ></path>
        <path
          fill="#9c3"
          fillOpacity="1"
          strokeWidth="0.028"
          d="M19.35 42.878c-.32-.232-.489-.595-.344-.74.048-.047.088-.056.224-.049.09.005.16.017.154.027-.007.01.031.044.083.075.129.075.188.253.188.561v.236l-.078-.001a.556.556 0 01-.227-.11z"
          display="inline"
        ></path>
        <path
          fill="#9c3"
          fillOpacity="1"
          strokeWidth="0.028"
          d="M25.527 42.98c-.048-.09-.065-.42-.026-.538a.545.545 0 01.138-.193.404.404 0 00.1-.108c-.005-.016.03-.021.252-.035.157-.01.189.02.19.183 0 .197-.184.487-.404.636-.177.119-.212.126-.25.055z"
          display="inline"
        ></path>
        <path
          fill="#9c3"
          fillOpacity="1"
          strokeWidth="0.028"
          d="M27.565 44.183c-.26-.086-.66-.087-.755-.002a.217.217 0 01-.072.05c-.01 0-.01-.171 0-.38.02-.434-.012-.723-.137-1.268-.107-.468-.138-.557-.297-.842a9.452 9.452 0 01-.206-.392c-.251-.524-.492-.801-.99-1.144-.133-.09-.3-.207-.372-.259a.699.699 0 00-.156-.094.463.463 0 01-.127-.1.4.4 0 01-.015-.578c.173-.17.443-.153.88.053.396.186.645.346 1.006.708.163.164.141.151.29.29.217.24.401.517.545.807.045.09.037.115.105.252.244.489.446 1.212.526 1.887.034.29.04 1.09.009 1.085a4.33 4.33 0 01-.234-.073z"
          display="inline"
        ></path>
      </g>
    </svg>
  );
}

export default Icon;
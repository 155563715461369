import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class Footer extends Component {
    render () {
        return (
            <footer id="fh5co-footer" role="contentinfo">
                <div className="container">
                    <div className="row">
                        <Bio />

                        <div className="col-md-1"></div>

                        <Menu menu={this.props.menu}/>
        
                        {/* <div className="col-md-2 col-xs-6 fh5co-widget">
                            <h4>Categories</h4>
                            <ul className="fh5co-footer-links">
                                <li><a href="#">Landing Page</a></li>
                                <li><a href="#">Real Estate</a></li>
                                <li><a href="#">Personal</a></li>
                                <li><a href="#">Business</a></li>
                                <li><a href="#">e-Commerce</a></li>
                            </ul>
                        </div> */}

                        <Contact/>
        
                    </div>

                    <Dedicace />
        
                </div>
            </footer>
        );
    }
}

function Menu(props) {
    return (
        <div className="col-md-3 col-xs-6 fh5co-widget">
            <h4>Liens</h4>
            <ul className="fh5co-footer-links">
                {props.menu.map( (menuItem, index) => {
                    return (
                        <li key={index}><a href={menuItem.url}>{menuItem.title}</a></li>
                    );
                }
                )}
            </ul>
        </div>
    )
}

function Bio(props) {
    return (
        <div className="col-md-4 fh5co-widget">
            <h4>Atlantique For Good</h4>
            <p>Nous mettons en relation les étudiants et les structures actrices de la transition écologique et sociale afin d'avoir un double impact : guider les étudiants vers des carrières conscienseuses et aider les organisations responsable à transformer leurs processus et ainsi gagner en performance.</p>
        </div>
    )
}

function Contact(props) {
    return (
        <div className="col-md-4 fh5co-widget">
            <h4>Contact</h4>
            <ul className="fh5co-footer-links">
                <li><i>4 Rue Alfred Kastler, Nantes <br />655 Avenue du Technopôle, Plouzané<br/>IMT Atlantique</i></li>
                <li><a href="mailto:atlantique.for.good@gmail.com">atlantique.for.good@gmail.com</a></li>
                <li><a href="contact">Contactez-nous via le formulaire.</a></li>
                {/* <li><a href="tel://1234567920">+33 6 48 26 29 87</a></li> */}
            </ul>
        </div>
    )
}

function Dedicace(props) {
    return( 
        <div className="row copyright">
            <div className="col-md-12 text-center">
                <ul className="fh5co-social-icons">
                    <li><a href="/"><i className="icon-linkedin"></i></a></li>
                    <li><Link target="_blank" to="https://www.instagram.com/atlantique_for_good/"><i className="icon-instagram"></i></Link></li>
                    <li><a href="/"><i className="icon-facebook"></i></a></li>
                    {/* <li><a href="#"><i className="icon-dribbble"></i></a></li> */}
                </ul>
                <p>
                    <small className="block">Créé en 2023 au sein d'<a href="https://www.imt-atlantique.fr">IMT Atlantique</a>.</small> 
                    <small className="block">Site web éco-conçu et sobre en ressources.</small>
                </p>
            </div>
        </div>
    )
}
import React from "react";
import { useState, useEffect, useRef } from "react";
import MediaQuery from "react-responsive"
import {OffCanvas, OffCanvasMenu} from "react-offcanvas"
import {Link} from "react-router-dom"
import { useLocation } from "react-router-dom";

import Logo from "./static/images/logo.js"
//import banner from "./static/images/user-1.jpg"


function Head(props) {
    const [isMenuOpened, setMenuOpen] = useState(false)
    let btnCanva=useRef()

    return (
        <>
            <MediaQuery maxWidth={992}>
                <ButtonOffCanvas btnCanva={btnCanva} setMenuOpen={setMenuOpen} isMenuOpened={isMenuOpened}/>
                <NavbarMobile refBtn={btnCanva} menu={props.menu} isMenuOpened={isMenuOpened} setMenuOpen={setMenuOpen}/>
                <nav className="fh5co-nav" role="navigation">
                    <div className="top-menu">
                        <div className="container">
                            <div className="row">
                            <div className="col-xs-2">
                                <div className="center"><Link to="/"><Logo  size = {40} title = {false}/></Link></div>
                            </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </MediaQuery>
            <MediaQuery minWidth={992.1}>
                <nav className="fh5co-nav" role="navigation">
                    <div className="top-menu">
                        <div className="container">
                            <div className="row">
                            <div className="col-xs-2">
                                <div className="center"><Link to="/"><Logo  size = {70} title = {false}/></Link></div>
                            </div>
                            <div className="col-xs-2"></div>
                            <NavbarDesktop menu={props.menu}/>
                            </div>
                        </div>
                    </div>
                </nav>
            </MediaQuery>
        </>
    );
}

function NavbarItem(props) {
    const [dropdown, setDropdown] = useState(false);
    const location = useLocation()

    return(
        <>
        {props.menu.submenu ? (
            <li className={"has-dropdown " + `${location.pathname.includes(props.menu.url) ? "active" :""} + ${props.menu.class ? props.menu.class : ""}`}
            aria-expanded={dropdown ? "true" : "false"}
            onMouseEnter={() => setDropdown(true)}
            onMouseLeave={() => setDropdown(false)}
            >
                    <a href={props.menu.url}>{props.menu.title}</a>
                    <i className={dropdown ? "caret-up" : "caret-down"}></i>
                    <Dropdown submenu={props.menu.submenu} dropdown={dropdown}/>
            </li>
            ) : (
            <li className={`${location.pathname.includes(props.menu.url) ? !(location.pathname=="/" ^ props.menu.title=="Accueil") ? "active" : "" : ""} ${props.menu.class ? props.menu.class : ""}`}>
                <a href={props.menu.url}>{props.menu.title}</a>
            </li>
        )}
        </>
    );
}

function Dropdown(props) {
    return(
        <ul
        className={`dropdown ${props.dropdown ? "animated-fast fadeInUpMenu" : ""}`}
        style={{display: props.dropdown ? "block":"none"}}
        >
            {props.submenu.map((subitem, index) => (
                <li key={index}><a href={subitem.url}>{subitem.title}</a></li>
            ))
            }
        </ul>
    );
}

function ButtonOffCanvas (props) {
    return (
        <a
        className={`js-fh5co-nav-toggle fh5co-nav-toggle fh5co-nav-white ${props.isMenuOpened ? "active" : ""}`}
        ref={props.btnCanva}
        onClick={() => onClickCanvas(props.isMenuOpened, props.setMenuOpen)}
        >
            <i></i>
        </a>
    )
}

function onClickCanvas (isMenuOpened, setMenuOpen) {
    if(isMenuOpened){
        document.body.classList.remove("overflow");
        document.body.classList.remove("offcanvas");
    } else {
        document.body.classList.add("overflow");
        document.body.classList.add("offcanvas");
    }
    setMenuOpen(!isMenuOpened);
}

class NavbarDesktop extends React.Component{

    render() {
        return (
            <div className="col-xs-8 text-right menu">
                <ul style={{display: "flex",justifyContent: "space-evenly"}}>
                    {this.props.menu.map((menuItem, index) => {
                    return(
                        <NavbarItem
                        menu={menuItem}
                        key={index}/>
                    );
                    })}
                </ul>
            </div>
        );
    }

}

function NavbarMobile (props) {


    // render() {
    let canvaRef = useRef()

    useEffect(() => {
        let handler = (e) => {
            if(!props.refBtn.current.contains(e.target) && !canvaRef.current.contains(e.target)){
                onClickCanvas(props.isMenuOpened, props.setMenuOpen)
            }
        }
        if(props.isMenuOpened) {//We had a listener only if menu is opened
            document.addEventListener("mousedown", handler)
        }

        return () => {//return when effect finish
            document.removeEventListener("mousedown", handler)
        }

    })

    return (
        <OffCanvas
            width={300}
            transitionDuration={300}
            effect={"parallax"}
            isMenuOpened={props.isMenuOpened}
            position={"right"}
        >
            <OffCanvasMenu style={{height: '100%', zIndex:'2'}}>
                <div ref={canvaRef} id="fh5co-offcanvas" className="fh5co-offcanvas">
                    <ul>
                        {props.menu.map((menuItem, index) => {
                        return(
                            <NavbarItem
                            menu={menuItem}
                            key={index}/>
                        );
                        })}
                    </ul>
                </div>
            </OffCanvasMenu>
        </OffCanvas>
    );
}

export default Head;
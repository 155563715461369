import { AutoForm } from "../forms/dynamic-form";
import { buildForm } from "../forms/build-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import { csrftoken } from "../csrftoken";

const base_URL = "https://" + window.location.hostname

export const FormRequest = (props) => {
    const [stateBtn,  setStateBtn] = useState("")
    const [errors,  setErrors] = useState([])
    
    const JSONschema = props.form;
    console.log(JSONschema)

    const { formFields, schema } = buildForm({
        JSONschema,
        exclude: ["created_at", "created_by"],
    });
    
    const formHook = useForm({ resolver: yupResolver(schema) });
    
    const onSubmit = (data) => {
      setStateBtn("onclic")
      PostData(data, props.path).then((req) => validation(req, setStateBtn, setErrors, props.form))
    };
    
    if('ERROR' in props.form)
      return <h3 className='text-center'>{props.form['ERROR']}</h3>
    return (
    <AutoForm
        fields={formFields}
        formHook={formHook}
        onSubmit={onSubmit}
        stateBtn={stateBtn}
        errors={errors}
        className="form" // className={"grid gap-4 md:grid-cols-2"}
        />
    );
  };
  

const validation = async (req, setStateBtn, setErrors, form, redirect) => {
  console.log(req.ok)
  if(req.ok) {
    setTimeout(() => {
        setStateBtn("validate");
        setTimeout(() => {if(redirect) {window.location.href=base_URL + redirect}
        else {window.location.reload()}},1600)
    },500)
    //erase data of the form or redirect
  } else {
    setTimeout(() => {setStateBtn("error")},300)

    let errRes = []
    for(var error in req.res){
      errRes.push({'key':form[error].label,'description':req.res[error]})
    };
    console.warn(errRes)
    setErrors(errRes)
  }
  setTimeout(() => {setStateBtn("")}, 1600)
}

export const PreloadForm = async (path) => {
  let urlBack = base_URL + "/api/" + path + "/";
  // console.log("path : " + path)
  // console.log("hostname : " + window.location.hostname)
  // console.log("URL API : " + urlBack)
  
    const res = await fetch(urlBack, {
      method: "OPTIONS",
      headers: {
        "Content-Type": "application/json",
      },
    });
    
    return res.json()
}

const PostData = async (data, path) => {
  let url = base_URL + "/api/" + path + "/";
    
    const res = await fetch(url, {
      credentials: 'include',
      method: 'POST',
      // mode: 'same-origin',
      headers: {
        'Accept':'application/json',
        'Content-Type':'application/json',
        'X-CSRFToken': csrftoken
        },
        body: JSON.stringify(data)
      })
      return {ok:res.ok, res:res.json()};
}

// use like :
// 
// <Form path="contact"/>

export default function Form(props) {
    const [form, setForm] = useState();

    useEffect(() => {
        PreloadForm(props.path).then((res) => {
          if('id' in res.actions.POST){
            setForm(res.actions.POST)
          } else {
            setForm({'ERROR': 'Erreur lors de la construction du formulaire'})
          }
        })
    }, [props.path]);

    return (form === undefined ? <></> : <FormRequest form={form} {...props}/>)
}
import React, { Component } from 'react';
import {Waypoint} from 'react-waypoint'

import { Link } from 'react-router-dom';

export default function Home() {
  return (
    <>
      <Banner />
      {/* <CallToAction /> */}
    </>
  );
}

class Banner extends React.Component {
  render () {
    return (
    <header id="fh5co-header" className="fh5co-cover fullheight" role="banner" >
      {/* <div className="overlay"></div> */}
      <div className="container">
        <div className="row">
          <div className="col-md-8 col-md-offset-2 text-center">
            <div className="display-t fullheight">
              <div className="display-tc fullheight animate-box fadeIn animated-fast">
                <h1>Mettre en relation <b>étudiants</b> &amp; structures <b>actrices de la transition</b></h1>
                <h2>Découvrez la force d'Atlantique For Good</h2>
                <p><Link to='etudiant' className="btn btn-primary btn-lg btn-demo">Je suis étudiant</Link> <Link className="btn btn-primary btn-lg btn-learn" to="/structure">Je suis une structure</Link></p>
              </div>
            </div>
          </div>
        </div>
      </div>
  	</header>
    )
  }
}

// class Features extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {animate : false}
//     //this.setState = this.setState.bind(this)
//   }

//   render () {
//     return (
//       <div id="fh5co-features">
//         <div className="container">
//           <div className="services-padding">
//             <Waypoint
//               onEnter={() => this.setState({animate : true})}
//               onLeave = {() => this.setState({animate : false})}
//               scrollableAncestor={"window"}>
//             <div className="row">
//               <FeaturesItem time="200" animate={this.state.animate} icon="icon-hotairballoon" head="Marketing" text="Facilis ipsum reprehenderit nemo molestias. Aut cum mollitia reprehenderit." CallToAction="Learn More"/>
//               <FeaturesItem time="400" animate={this.state.animate} icon="icon-search" head="Search Engine" text="Facilis ipsum reprehenderit nemo molestias. Aut cum mollitia reprehenderit." CallToAction="Learn More"/>
//               <FeaturesItem time="600" animate={this.state.animate} icon="icon-wallet" head="Earn Money" text="Facilis ipsum reprehenderit nemo molestias. Aut cum mollitia reprehenderit." CallToAction="Learn More"/>
//               <FeaturesItem time="800" animate={this.state.animate} icon="icon-wine" head="Entrepreneur" text="Facilis ipsum reprehenderit nemo molestias. Aut cum mollitia reprehenderit." CallToAction="Learn More"/>
//               <FeaturesItem time="1000" animate={this.state.animate} icon="icon-genius" head="Stragic Plan" text="Facilis ipsum reprehenderit nemo molestias. Aut cum mollitia reprehenderit." CallToAction="Learn More"/>
//               <FeaturesItem time="1200" animate={this.state.animate} icon="icon-chat" head="Support" text="Facilis ipsum reprehenderit nemo molestias. Aut cum mollitia reprehenderit." CallToAction="Learn More"/>
//             </div>
//             </Waypoint>
//           </div>
//         </div>
//       </div>
//     )
//   }
// }

// function FeaturesItem (props) {
//   const [reveal, setReveal] = useState(false)


//    useEffect(() => {
//     const timer = setTimeout(() => (props.animate ? setReveal(true) : ""), props.time);
//     return () => clearTimeout(timer);
//    })

//   return (
//     <div className={`col-md-4 animate-box ${reveal ? " fadeInUp animated-fast" : ""}`}>
//       <div className="feature-left">
//         <span className="icon">
//           <i className={props.icon}></i>
//         </span>
//         <div className="feature-copy">
//           <h3>{props.head}</h3>
//           <p>{props.text}</p>
//           {props.CallToAction}<i className="icon-arrow-right22"></i>
//         </div>
//       </div>
//     </div>
//   )
// }

class CallToAction extends Component {
  constructor(props) {
    super(props)
    this.state = {animate : false}
  }

  render () {
    return (
        <Waypoint
        onEnter={() => this.setState({animate : true})}
        scrollableAncestor={window}>
        <div id="fh5co-started">
          <div className="overlay"></div>
          <div className="container">
            <div className={`row animate-box ${this.state.animate ? " fadeInUp animated" : ""}`}>
              <div className="col-md-8 col-md-offset-2 text-center fh5co-heading">
                <h2>Passez à <nobr>l'action !</nobr></h2>
                <p>Faites de vos outils technologiques une force, concentrez-vous sur votre impact.<br />L'excellence d'IMT Atlantique et l'expérience de nos mentors-partenaires vous donneront les atouts pour changer vos processus et Scale votre activité.</p>
                <p><a href="#" className="btn btn-default btn-lg">Candidater</a></p>
              </div>
            </div>
          </div>
        </div>
        </Waypoint>
    )
  }
}

// class Wireframe extends Component {
//   constructor(props) {
//     super(props)
//     this.state = {animate : false}
//   }

//   render () {
//     return (
//       <div id="fh5co-wireframe">
//       <div className="container">
//         <Waypoint
//         onEnter={() => this.setState({animate : true})}
//         scrollableAncestor={window}/>
        
//         <div className={`row animate-box ${this.state.animate ? "fadeInUp animated-fast" : ""}`}>
//           <div className="col-md-8 col-md-offset-2 text-center fh5co-heading">
//             <h2>Wireframe Connects the Underlying Conceptual Structure</h2>
//           </div>
//         </div>
//         <div className="row">
//           <div className={`col-md-5 animate-box ${this.state.animate ? "fadeInUp animated-fast" : ""}`}>
//             <div className="user-frame">
//               <h3>Wireframe Connects the Underlying Conceptual Structure</h3>
//               <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts far from the countries Vokalia and Consonantia, there live the blind texts. </p>
//               <span>Louie Jie Mahusay</span><br />
//               <small>CEO, Founder</small>
//             </div>
//           </div>
//           <div className={`col-md-7 animate-box ${this.state.animate ? "fadeInUp animated-fast" : ""}`}>
//             <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts far from the countries Vokalia and Consonantia, there live the blind texts. </p>
//             <blockquote>
//               <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts far from the countries Vokalia and Consonantia, there live the blind texts. </p>
//             </blockquote>
//             <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts far from the countries Vokalia and Consonantia, there live the blind texts. far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts far from the countries Vokalia and Consonantia, there live the blind texts. </p>
//           </div>
//         </div>
//       </div>
//     </div>
//     )
//   }
// }

// class Blog extends Component {
//   constructor(props) {
//     super(props)
//     this.state = {animate : false}
//   }

//   render () {
//     return (

//     <div id="fh5co-blog">
//       <div className="container">
//         <div className={`row animate-box ${this.state.animate ? "fadeInUp animated-fast" : ""}`}>
//           <div className="col-md-8 col-md-offset-2 text-center fh5co-heading">
//             <h2>Recent Post</h2>
//             <p>Dignissimos asperiores vitae velit veniam totam fuga molestias accusamus alias autem provident. Odit ab aliquam dolor eius.</p>
//           </div>
//         </div>
//         <div className="row">
//           <div className="col-md-4">
//             <div className={`fh5co-blog animate-box ${this.state.animate ? "fadeInUp animated-fast" : ""}`}>
//               <a href="#" className="blog-bg" style={{backgroundImage : img_blog_1}}></a>
//               <div className="blog-text">
//                 <span className="posted_on">Feb. 15th 2016</span>
//                 <h3><a href="#">Photoshoot On The Street</a></h3>
//                 <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
//                 <ul className="stuff">
//                   <li><i className="icon-heart2"></i>249</li>
//                   <li><i className="icon-eye2"></i>1,308</li>
//                   <li><a href="#">Read More<i className="icon-arrow-right22"></i></a></li>
//                 </ul>
//               </div> 
//             </div>
//           </div>
//           <div className="col-md-4">
//             <div className={`fh5co-blog animate-box ${this.state.animate ? "fadeInUp animated-fast" : ""}`}>
//               <a href="#" className="blog-bg" style={{backgroundImage : img_blog_2}}></a>
//               <div className="blog-text">
//                 <span className="posted_on">Feb. 15th 2016</span>
//                 <h3><a href="#">Surfing at Philippines</a></h3>
//                 <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
//                 <ul className="stuff">
//                   <li><i className="icon-heart2"></i>249</li>
//                   <li><i className="icon-eye2"></i>1,308</li>
//                   <li><a href="#">Read More<i className="icon-arrow-right22"></i></a></li>
//                 </ul>
//               </div> 
//             </div>
//           </div>
//           <div className="col-md-4">
//             <div className={`fh5co-blog animate-box ${this.state.animate ? "fadeInUp animated-fast" : ""}`}>
//               <a href="#" className="blog-bg" style={{backgroundImage : img_blog_3}}></a>
//               <div className="blog-text">
//                 <span className="posted_on">Feb. 15th 2016</span>
//                 <h3><a href="#">Focus On Uderwater</a></h3>
//                 <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
//                 <ul className="stuff">
//                   <li><i className="icon-heart2"></i>249</li>
//                   <li><i className="icon-eye2"></i>1,308</li>
//                   <li><a href="#">Read More<i className="icon-arrow-right22"></i></a></li>
//                 </ul>
//               </div> 
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//     )
//   }
// }

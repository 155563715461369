import * as Yup from "yup";

const getYupType = (schemaObject) => {
  switch (schemaObject.type) {
    case "text":
      return Yup.string();
    case "number":
      return Yup.number();
    case "boolean":
      return Yup.boolean();
    case "file":
      return Yup.mixed();
    case "array":
      return Yup.array();
    case "object":
      return Yup.object();
    case "date":
      return Yup.date();
    /* case "time":
      return Yup.time();
    case "datetime":
      return Yup.datetime(); */
    default:
      return Yup.string();
  }
};

const checkRequired = (schemaObject, yupObject) => {
  if (schemaObject.required) {
    return yupObject.required();
  }
  return yupObject;
};

const addValidation = (schemaObject, yupObject) => {
  let obj = yupObject;
  if (schemaObject.max_length && schemaObject.type !== "file") {
    obj = obj.max(
      schemaObject.max_length,
      `${schemaObject.title} must be less than ${schemaObject.max_length} characters`
    );
  }
  return obj;
};

export const generateYupSchema = (schema) => {
  let shape = {};
  Object.keys(schema).forEach((key) => {
    let schemaObject = schema[key];
    let yupObject = getYupType(schemaObject);
    yupObject = checkRequired(schemaObject, yupObject);
    yupObject = addValidation(schemaObject, yupObject);
    shape = { ...shape, ...{ [key]: yupObject } };
  });
  let yupSchema = Yup.object().shape(shape);
  return yupSchema;
};
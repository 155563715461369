import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import deroulement from "./static/images/deroulement.jpg"
import cdc from "./static/images/CDC.jpg"
import groupe from "./static/images/groupes.jpg"
import { Waypoint } from "react-waypoint";

import Features from "./components/Features";
import Slider from "./components/Slider";
import Content from "./components/content";
import Form from "./forms/loader-form";

const slides = [
    {
        // title: <>Le déroulement d'une <b>mission</b></>,
        img: deroulement,
    },
    {
        title: <>La <b>rencontre</b> et la formulation du <b>besoin</b></>,
        text: <>Nous pensons que le projet est d'abord une rencontre, nous commençons donc par apprendre à vous connaitre : votre structure, votre activité, votre impact,vos ambitions... Afin d'assurer que le projet a un impact potentiel et par souci de transparence, nous le faisons valider au sein de la communauté avant de constituer le cahier des charges.</>,
        img: cdc,
    },
    {
        title: <>Des missions par <b>groupes d'étudiants</b></>,
        text: <>Nous constituons l'équipe d'hack'teurs en parallèle du cahier des charges. Celle-ci sera constituée de 2 à 6 hack'teurs et peut être supervisée par un mentor (alumni ou partenaire) si besoin. L'équipe assure des points réguliers avec le bénéficiaire afin de garantir efficacité et agilité.</>,
        img: groupe,
    },
]

const features = [
    {
        icon:"audits",
        head:"Audits",
        text:"Les audits nous permettent d’assurer un accompagnement plus complet : en commençant d’abord par un état des lieux puis en délivrant des recommandations que nous pourrons mettre en place.",
        CTA:"",
        CTA_text:"",
    },
    {
        icon:"benchmark",
        head:"Benchmark",
        text:<>Vous ne savez pas quel outil choisir ? La suite Google, Office ou d'autres alternatives, laquelle correspond le mieux à vos attentes ?<br/>Laissez nos étudiants étudier les différentes solutions et vous conseiller sur les outils à explorer.</>,
        CTA:"",
        CTA_text:"",
    },
    {
        icon:"data",
        head:"Data",
        text:"Beaucoup d'étudiants suivent des formations en données : data science, business intelligence, data architecture... Lesquels se porteront volontaires pour nager dans votre océan de données ?",
        CTA:"",
        CTA_text:"",
    },
    {
        icon:"website",
        head:"Site web",
        text:"La réalisation de site web est souvent un besoin fondamental pour une PME ou association. Nous pouvons vous réaliser tout ou partie d'un site et ainsi vous permettre de vous faire connaitre ou de changer d’échelle !",
        CTA:"",
        CTA_text:"",
    },
    {
        icon:"automatisation",
        head:"Automatisation",
        text:"Automatiser certains process vous permettra de consacrer plus de temps à votre activité. Dashboard, reporting, automatisation comptable… ne laissez plus certaines tâches pénibles occuper votre temps !",
        CTA:"",
        CTA_text:"",
    },
    {
        icon:"tableur",
        head:"Tableur",
        text:"Les outils comme Excel ou Airtable sont aujourd'hui très répandus, pourtant il est parfois compliqué de les manipuler et les maitriser. Nous pouvons vous préparer des vues, pages, templates… qui vous permettront d’exploiter pleinement le potentiel de vos outils !",
        CTA:"",
        CTA_text:"",
    },
]

const realisation = [
    {
        title:<>Energie jeunes<br/><span className="text-mute">(En préparation) Optimisation d'un process de saisie de questionnaires.</span></>,
        text:<>Analyser l’ensemble du process, identifier les points stratégiques et les potentiels blocages, et proposer des solutions pour l’optimiser ; et ainsi gagner du temps et le revaloriser pour démultiplier leur actions.</>
    },
    {
        title:<>L'ISA<br/><span className="text-mute">(En cours) Mise à jour et restructuration du site.</span></>,
        text:<>Refonte du site <Link target="_blank" to="https://www.l-isa.org">www.l-isa.org</Link>, afin d'avoir une actualisation des informations et une meilleure expérience utilisateur, pour continuer dans la phase de changement d'échelle que l'association a opérée !</>
    },
    {
        title:<>+<br/><span className="text-mute">N'hésitez pas à nous soumettre votre besoin !</span></>,
        text:<>Vous pouvez passer par le formulaire (à venir) ou par <Link to="/contact">l'espace de contact</Link> afin qu'un membre traite votre demande.</>
    },
]

export default function Structure (props) {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
            <>
        <About />
        <Slider title="Notre fonctionnement" items={slides} />
        <Features items={features} isAnimate={true} />
        <CallToAction />
        <Content title="Nos réalisations" items={realisation} />
        </>
    )
}


function About (props) {
    const [animate, setAnimate] = useState(false)

    return (
    <div id="fh5co-about">
		<div className="container">
        <Waypoint
        onEnter={() => setAnimate(true)}
        scrollableAncestor={window}>
        <div className="row">
			<div className={`row animate-box ${animate ? "fadeInUp animated-fast" : ""}`}>
				<div className="col-md-11 col-md-offset-1 fh5co-heading">
					<h2>Pourquoi nous faire confiance ?</h2>
					<p>Vous êtes une entreprise, association ou organisation actrice de la transition sociale et environnementale et vous adoptez un modèle innovant ? Vos ressources sont limitées car vous vous lancez ou parce que votre activité est à lucrativité limitée ?</p>
                    <p>Chez Atlantique For Good, nous pensons que pour transformer nos modèles économiques, les structures à impacts doivent être performantes.
                        Nous cherchons à les renforcer et booster leur activité en apportant nos compétences d'étudiants ingénieurs sur les sujets technologiques.
                        Nous avons fait le choix de cibler l'IT car nous sommes convaincus que c’est un puissant facteur de développement, qui permettra in fine d’augmenter votre impact.
                    </p></div>
			</div>
			<div className={`row animate-box ${animate ? "fadeInUp animated-fast" : ""}`}>
				<div className="col-md-11 col-md-offset-1 fh5co-heading">
					<h3>Qu'ai-je à y gagner ?</h3>
                    <p>Nous souhaitons créer une synergie entre le monde académique et le monde de l’impact. Notre ambition est de donner un coup de pouce à des structures vertueuses, à travers notre formation à IMT Atlantique ; et ainsi inscrire les étudiants comme éléments clés de leur développement.</p>
                    <p>Au-delà des missions réalisées, vous serez en contact direct avec les étudiants, nouvelles parties prenantes de votre projet : ambassadeurs, collaborateurs...</p>
                    <p>En intervenant au cœur de votre activité, ils vont découvrir de nouvelles façons de penser l’entreprenariat, la performance, les enjeux sociétaux et environnementaux... ce qui ne peut qu’être bénéfique pour l’avenir de notre société.</p>
				</div>
			</div>
			<div className={`row animate-box ${animate ? "fadeInUp animated-fast" : ""}`}>
				<div className="col-md-11 col-md-offset-1 fh5co-heading">
					<h3>Quel est mon engagement ?</h3>
                    <p>Afin de mener la mission au mieux, nous exigeons un suivi régulier par au moins un décideur du projet. Cela nous permet de maintenir la cohérence avec l’objectif initial et assurer la réussite des réalisations.</p>
                    <p>Puis, au cours ou à l’issue d’une mission, nous encourageons le bénéficiaire à participer à la hauteur de ses moyens : une tarification libre de la mission, une master-class sur un savoir-faire spécifique, une invitation à un événement majeur de la structure… cette liste étant non exhaustive. </p>
				</div>
			</div>
        </div>
        </Waypoint>
		</div>
	</div>
    )
}


function CallToAction (props) {
    const [animate, setAnimate] = useState(false)
  
    return (
        <div id="fh5co-started">
            <div className="overlay"></div>
            <Waypoint
            onEnter={() => setAnimate(true)}
            scrollableAncestor={window}>
            <div className="container">
                <div className={`row animate-box ${animate ? " fadeInUp animated" : ""}`}>
                    <div className="col-md-8 col-md-offset-2 text-center fh5co-heading">
                        <h2>Passez à l'action !</h2>
                        <p>Faites de vos outils technologiques une force, concentrez-vous sur votre impact.<br />L'excellence d'IMT Atlantique et l'expérience de nos mentors-partenaires vous donneront les atouts pour améliorer vos processus et booster votre activité.</p>
                        <p><Link to="demande-mission" className="btn btn-default btn-lg">Demander un coup de pouce !</Link></p>
                    </div>
                </div>
            </div>
            </Waypoint>
        </div>
    )
  }

  
export const DemandeStructure = () => {
    
    useEffect(() => {
      window.scrollTo(0, 0)
    }, [])
  
    return (
      <>
        <div className="form" id="fh5co-form">
          <div className="container">
            <div className="bg-gray-100 px-16 py-8">
                <h2>Demander un coup de pouce</h2>
                <Form path={"demande"}/>
            </div>
          </div>
        </div>
        <Features items={features} isAnimate={true}/>
      </>
    )
  }
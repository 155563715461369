import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/esm/Row';
import { Waypoint } from 'react-waypoint';
import Icon from '../static/images/icons';


export default function Features(props) {

  const [animate, setAnimate] = useState(!props.isAnimate)

  return (
    <div id="fh5co-features">
      <Container fluid={true}>
        <div className="services-padding">
          <Waypoint
            onEnter={() => setAnimate(true)}
            onLeave = {() => setAnimate(!props.isAnimate)}
            scrollableAncestor={"window"}>
              <Row xs={"auto"} style={{display:"flex",flexWrap:"wrap"}}>
                
                {props.items.map((item, index) => {
                  return <FeaturesItem
                  key={index}
                  time={index*200}
                  animate={animate}
                  {...item}/>
                  })}
              
              </Row>
          </Waypoint>
        </div>
      </Container>
    </div>
  )
}

function FeaturesItem (props) {
  const [reveal, setReveal] = useState(false)

   useEffect(() => {
    const timer = setTimeout(() => (props.animate ? setReveal(true) : ""), props.time);
    return () => clearTimeout(timer);
   })

  return (
    <div className={`animate-box col-xs-12 col-sm-6 col-md-4 ${reveal ? " fadeInUp animated-fast" : ""}`}>
      <div className="feature-left">
        <span className="icon">
          <Icon name={props.icon} size={39} />
        </span>
        <div className="feature-copy">
          <h3>{props.head}</h3>
          <p>{props.text}</p>
          {props.CallToAction ?<div className= 'infobulle' aria-label={props.CTA_text}>{props.CallToAction} <i className="icon-arrow-right22"></i></div> : ''}
        </div>
      </div>
    </div>
  )
}

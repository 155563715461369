import CSRFToken from "../csrftoken";
import { Form } from "./form";

export const AutoForm = ({
    fields,
    formHook,
    onSubmit,
    submitButton,
    stateBtn,
    errors,
    ...props
  }) => {
    const { handleSubmit } = formHook;

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* <CSRFToken/> */}
        <div className='row' {...props}>
          <Form fields={fields} hook={formHook} {...props} />
        <div className="col-xs-12 text-center">
          <button type="submit" className={stateBtn + " btn"}>
            {/* {submitButton ? submitButton : "Envoyer"} */}
          </button>
        </div>
        </div>
        <ul className="alert">
          {errors.map((error) => <li>{error.key} : {error.description}</li>)}
        </ul>
      </form>
    );
  };
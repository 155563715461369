import {SearchableSelect, TextAreaInput, BooleanInput, Input} from "./input"

export const Form = ({ fields, hook }) => {
    // destructuring the hook object to get the form state and the form methods
    const {
      register,
      setValue,
      formState: { errors },
    } = hook;
  
    // map over the fields array and create a form component for each field
    return fields.map((item) => {
      if (item.type === "select")
        return (
          <div className={`form-group col-xs-12 col-md-6 ${item.required ? "form-required" : ""}`} key={item.name}>
            <SearchableSelect
              onChange={(option) => setValue(item.name, option.value)}
              {...item}
            />
            <span className="text-sm text-red-500">
              {errors[item.name]?.message}
            </span>
          </div>
        );
      if (item.type === "multiple choice") {
        item.options = item.choices.map((e) => {
          return {value : e.value, label : e.display_name}
        })
        return (
          <div className={`form-group col-xs-12 col-md-6 ${item.required ? "form-required" : ""}`} key={item.name}>
            <SearchableSelect
              onChange={(options) => {
                setValue(item.name, options.map((e) => e.value ).join(",") )
              }}
              isMulti={true}
              {...item}
            />
            <span className="text-sm text-red-500">
              {errors[item.name]?.message}
            </span>
          </div>
        );
      }
      if (item.type === "text"){
        if (item.help_text === "textarea")
          return (
            <div className={`form-group col-xs-12 ${item.required ? "form-required" : ""}`} key={item.name}>
              <TextAreaInput
                key={item.name}
                register={register}
                errors={errors}
                {...item}
              />
            </div>
          );
        return (
          <div className={`form-group col-xs-12 col-md-6 ${item.required ? "form-required" : ""}`} key={item.name}>
            <Input
              key={item.name}
              register={register}
              errors={errors}
              {...item}
            />
          </div>
        );
      }
  
      if (item.type === "checkbox")
        return (
          <div className={`form-group col-xs-12 col-md-6 ${item.required ? "form-required" : ""}`} key={item.name}>
            <BooleanInput
              key={item.name}
              register={register}
              errors={errors}
              {...item}
            />
          </div>
        );
  
      return (
        <div className={`form-group col-xs-12 col-md-6 ${item.required ? "form-required" : ""}`} key={item.name}>
          <Input key={item?.name} register={register} errors={errors} {...item} />
        </div>
      );
    });
  };
import React, { useState } from "react";
import { Waypoint } from "react-waypoint";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel"


export default function Slider (props) {
    const [animate, setAnimate] = useState(false)
    const [index, setIndex] = useState(0)

    return (
    <div id="fh5co-slider">
    <div className="container">
        <div className="heading text-center">
            <h2>{props.title}</h2>
            <p>{props.text}</p>
        </div>
        <Waypoint
        onEnter={() => setAnimate(true)}
        scrollableAncestor={window}>
        <div className="row flex-md" style={{alignItems:"center"}}>
        {/* <div className={`col-md-5 animate-box ${animate ? "fadeInUp animated-fast" : ""}`}>
            {props.item.map((item, ind) => {
                return <TextCarouselItem key={ind} {...item} display={index==ind}/>
            })} pull -> push-2
        </div> */}
        <div style={{padding:"0 2% 0 2%"}} className={`col-md-7 animate-box ${animate ? "fadeInUp animated-fast" : ""}`}>
            <aside id="fh5co-hero">
            <div className="flexslider">
                <div className="slides">
                <Carousel
                    showArrows={false} showStatus={false} showIndicators={true} showThumbs={false}
                    infiniteLoop={true} useKeyboardArrows={false} autoPlay={true} swipeable={true} emulateTouch={true}
                    stopOnHover= {true}
                    dynamicHeight={true}
                    autoFocus={false}
                    interval= {9000}
                    transitionTime={400}
                    onChange={(index) => {setIndex(index)}}>
                        {props.items.map((item, ind) => {
                            return <CarouselItem
                                key={ind} {...item} index={ind}/>
                        })}
                </Carousel>
                </div>
            </div>
            </aside>
                    {/* <div className="row">
                    <div className="col-md-12 col-md-offset-0 col-md-pull-10"> */}
                    {/* </div>
                    </div> */}
                {/* </div> */}
        </div>
        <div className="col-md-5 col-md-pull-1 col-md-offset-0">
            {props.items.map((item, ind) => {
                if(item.title) return <TextCarouselItem key={ind} {...item} display={index===ind}/>
                return <></>
            })}
        </div>
        </div>
        </Waypoint>
    </div>
    </div>
    )
}

function CarouselItem(props) {
    
    return (
    <div key={props.index} className="carousel-item center" ><img src={props.img}/></div>
    )
    // {/* </div>style={{backgroundImage: `url(${props.img})`}}*/}
}

function TextCarouselItem(props) {
    const [dispText, setDispText] = useState(false)

    console.log(dispText)
    return (
    <div className={`slider-text ${props.display && "active-text fadeInUp animated-fast"}`}>
    <div className="slider-text-inner">
        <div className={`desc ${dispText ? "display-text" : ""}`} onClick={() => setDispText(!dispText)}>
        <h2>{props.title}</h2>
        <p>{props.text}</p>
        </div>
    </div>
    </div>
    )
}
import React, { Component, useEffect } from 'react';
import {Waypoint} from 'react-waypoint'
import { Link } from 'react-router-dom';
import Form from "./forms/loader-form"
import Features from "./components/Features"

const features = [
  {
    icon:"briefcase",
    head:"Professionalisant",
    text:"Une expérience professionnelle à forte valeur ajoutée, des audits à mener.",
    CallToAction:"En savoir plus ",
    CTA_text:"Au delà des audits SI, les missions proposées sont en lien avec nos expertises tech : site web, automatisation Excel, business intelligence... etc",
  },
  {
    icon:"friends",
    head:"En groupe",
    text:"Seul on va plus vite, ensemble on va plus loin. Unissons nos forces et profitons de l'expérience d'un mentor.",
    CallToAction:"Pourquoi ? ",
    CTA_text:"Nous avons fait ce choix afin que les bénévoles puissent monter en compétences ensemble, tout en allégeant la charge de travail individuelle.",
  },
  {
    icon:"green_tech",
    head:"Trouve du sens",
    text:"Au travers de missions qui ont du sens, faites un pas dans le monde de l'impact.",
    CallToAction:"Pour qui ? ",
    CTA_text:"Les bénéficiaires sont des associations, entreprises, collectivités... Qui mettent au coeur de leur activité une cause environnementale ou sociale mais ont des ressources limitées.",
  },
  {
    icon:"tuition",
    head:"Des masterclass...",
    text:"...et des rencontres. Grâce à nos partenaires, tu as l'occasion d'acquérir de nouvelles compétences.",
    CallToAction:"Ah bon ? ",
    CTA_text:"Plus d'informations à venir prochainement...",
  },
  {
    icon:"atoms",
    head:"Un profil d'excellence",
    text:"Quel employeur ne rêve pas d'un ingénieur non seulement compétent mais aussi engagé ?",
  },
  {
    icon:"diversity",
    head:"Trouve de la diversité",
    text:"Prend part à un accompagnement complet du bénéficiaire de l'audit SI à la mise en place de solutions concrètes.",
    CallToAction:"C'est à dire ? ",
    CTA_text:"L'accompagnement d'une structure commence par un audit et une liste de recommandations jusqu'à la mise en place de certaines solutions."
  },
]

export default function Etudiants() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Wireframe />
      <Features items={features} isAnimate={true}/>

      <CallToAction />
    </>
  );
}

class Banner extends React.Component {
  render () {
    return (
    <header id="fh5co-header" className="fh5co-cover fullheight" role="banner" >
      <div className="overlay"></div>
      <div className="container">
        <div className="row">
          <div className="col-md-8 col-md-offset-2 text-center">
            <div className="display-t fullheight">
              <div className="display-tc fullheight animate-box fadeIn animated-fast">
                <h1>Mettre en relation <b>étudiants</b> &amp; structures <b>actrices de la transition</b></h1>
                <h2>Découvrez la force d'Atlantique For Good</h2>
                <p><a className="btn btn-primary btn-lg btn-demo" href="#">Je suis étudiant</a> <a className="btn btn-primary btn-lg btn-learn">Je suis une structure</a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
  	</header>
    )
  }
}

class CallToAction extends Component {
  constructor(props) {
    super(props)
    this.state = {animate : false}
  }

  render () {
    return (
        <div id="fh5co-started">
          <div className="overlay"></div>
          <div className="container">
            <div className={`row animate-box ${this.state.animate ? " fadeInUp animated" : ""}`}>
              <div className="col-md-8 col-md-offset-2 text-center fh5co-heading">
                <h2>Passe à l'action !</h2>
                <p>Monte en compétence et apporte un soutien important aux structures à impact !</p>
                <p>
                <Link to="inscription" className="btn btn-default btn-lg">Rejoindre la communauté</Link>
                <Link style={{background:"#00B8DE",color:"white", marginLeft:"8px"}} to="inscription" className="btn btn-default btn-lg">Rejoindre une mission</Link>
                </p>
              </div>
            </div>
          <Waypoint
          onEnter={() => this.setState({animate : true})}
          scrollableAncestor={window} />
          </div>
        </div>
    )
  }
}

class Wireframe extends Component {
  constructor(props) {
    super(props)
    this.state = {animate : false}
  }

  render () {
    return (
      <div id="fh5co-wireframe">
      <div className="container">
        <Waypoint
        onEnter={() => this.setState({animate : true})}
        scrollableAncestor={window}>
        
        <div className={`row animate-box ${this.state.animate ? "fadeInUp animated-fast" : ""}`}>
          <div className="col-md-8 col-md-offset-2 text-center fh5co-heading">
            <h2>Pourquoi nous rejoindre ?</h2>
          </div>
          <div className={`col-md-5 animate-box ${this.state.animate ? "fadeInUp animated-fast" : ""}`}>
            <div className="user-frame">
                <h3>La communauté des <b>Hack'teurs.rices</b></h3>
                <p>
                Acteur.rice <i>[n.masc./fem.] : Personne qui prend une part active, joue un rôle important.</i>
                <br/><small style={{float:"right"}}>Le Robert</small>
                </p>
                <p>Notre communauté met à contribution ses compétences d'ingénieurs pour aider des structures vertueuses, actrices de la transition. Elle a l'ambition de renforcer leurs performances numériques.
                </p>
            </div>
          </div>
          <div className={`col-md-7 animate-box ${this.state.animate ? "fadeInUp animated-fast" : ""}`}>
            <p>Nous avons pour projet de compléter les initiatives existantes en répondant d'une certaine façon à une <b>envie d'agir</b>.</p>
            <p>Envie d'assurer la <b>continuité de notre formation</b> à IMT Atlantique - <i>profondément ancrée dans la transition écologique et sociale</i> - en mettant nos acquis au profit de missions engagées.<br/>
            Envie d'entrer sur le marché du travail avec des <b>expériences auprès de structures éthiques</b>.<br/>
            Envie de <b>se sentir utile</b> et de contribuer à notre échelle à l'émergence des nouveaux modèles.
            </p>
            <blockquote>
              <p>Nous souhaitons faire naître une nouvelle synergie entre la formation, le monde associatif étudiant et le milieu professionnel, au service du bien commun.</p>
            </blockquote>
          </div>
        </div>
        </Waypoint>
      </div>
    </div>
    )
  }
}

export const HackteurInscription = () => {
    
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <div className="form" id="fh5co-form">
        <div className="container">
          <div className="bg-gray-100 px-16 py-8 text-center">
              <h2>Rejoindre la communauté</h2>
              <p>Pour recevoir toutes nos actus et être prévenus lors d'une nouvelle mission</p>
              <Form path={"hackteurs"}/>
          </div>
        </div>
      </div>
      <Features items={features} isAnimate={true}/>
    </>
  )
}

export const MissionInscription = () => {
    
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <div className="form" id="fh5co-form">
        <div className="container">
          <div className="bg-gray-100 px-16 py-8">
              <h2>Candidater à une mission</h2>
              <Form path={"candidature"} redirect={"/etudiant/candidature-mission"}/>
          </div>
        </div>
      </div>
      <Features items={features} isAnimate={true}/>
    </>
  )
}